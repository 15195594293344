import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoleSelectResponse, SearchRoleResponse } from '../_models/roleModel';
import { SelectItem } from '../_models/baseModel';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  http = inject(HttpClient);

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  baseUrl = `${environment.apiUrl}${environment.roleService}`; // URL to web api
  constructor() {}

  // Get all roles
  searchRoles(
    roleName: string,
    status: string,
    pageNumber: number,
    pageSize: number
  ) {
    return this.http.get<SearchRoleResponse>(`${this.baseUrl}`, {
      params: {
        roleName: roleName,
        status: status,
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
      },
      headers: this.httpOptions.headers,
    });
  }

  // Create a new role
  createRole(roleName: string, description: string, createBy: string) {
    return this.http.post(
      `${this.baseUrl}`,
      {
        roleName: roleName,
        description: description,
        createBy: createBy,
      },
      this.httpOptions
    );
  }

  // Update a role
  updateRole(
    roleId: string,
    roleName: string,
    description: string,
    status: string,
    updatedBy: string
  ) {
    return this.http.put(
      `${this.baseUrl}`,
      {
        roleId: roleId,
        roleName: roleName,
        description: description,
        status: status,
        updatedBy: updatedBy,
      },
      this.httpOptions
    );
  }

  // Delete a role
  deleteRole(roleId: string, deletedBy: string) {
    return this.http.delete(`${this.baseUrl}/${roleId}/${deletedBy}`, {
      headers: this.httpOptions.headers,
    });
  }

  getRolesSelect() {
    return this.http.get<RoleSelectResponse>(`${this.baseUrl}/select`, {
      headers: this.httpOptions.headers,
    });
  }
}
